import { Job } from './job';

export interface ISalaryTable {
  cao_id: string;
  salary_table_id: number;
  active_per: string;
  active_per_description: string;
  wages_per: string;
  wages_divided_by: number;
  description: string;
  min_age: number | null;
  max_age: number | null;
  name: string;
  hours_in_workweek: number;
  normal_workweek: boolean;
  workweeks: number[];
  payscales: string[];
  jobs: Job[];
  adv: number;
  abu_calc_available: boolean;
  adv_type?: string;
  full_table?: { [age: number]: { [scale: number]: { [periodical: number]: number } } };
  periodiek_verhogingen?: Array<{
    trigger_date: string;
    type: string;
    required_time: string;
    required_time_type: string;
    measurement_type: string;
    date_required_time_interaction: string;
  }>;
  bbl: boolean | undefined;
}

export interface IInlenerSalaryTable {
  inlener_cao_id: string;
  salary_table_id: number;
  active_per: string;
  description: string;
  conform: string;
  min_age: number | null;
  max_age: number | null;
  name: string;
  hour_definition: string;
  shift_work_comment: string;
  hours_in_workweek: number;
  initial_scaling: string;
  normal_workweek: boolean;
  workweeks: number[];
  payscales: string[];
  jobs: Job[];
  adv_type: string;
  adv_amount: string;
  adv_comment: string;
  adv: number;
  abu_calc_available: boolean;
  wages_per: string;
  wages_divided_by: number;
  full_table: { [age: number]: { [scale: number]: { [periodical: number]: number } } };
  periodiek_verhogingen: Array<{
    trigger_date: string;
    type: string;
    required_time: string;
    required_time_type: string;
    measurement_type: string;
    date_required_time_interaction: string;
  }>;
  bbl: boolean | undefined;
}

export class SalaryTable implements ISalaryTable {
  public cao_id = '';
  public salary_table_id = 0;
  public active_per = '';
  public active_per_description = '';
  public description = '';
  public min_age = 0;
  public max_age = 0;
  public name = '';
  public hours_in_workweek = 0;
  public normal_workweek = false;
  public workweeks = [];
  public payscales = [];
  public jobs = [];
  public adv = 0;
  public abu_calc_available = false;
  public wages_per = '';
  public wages_divided_by = 1;
  public bbl = undefined;
}
